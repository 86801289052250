@import "./sanitize.css";
@import "./customMediaQueries.scss";
@import "assets/styles/propertySets";

/* ================ Global element styles ================ */

// -------------------Fonts-----------------------//
@font-face {
    font-family: "Poppins";
    src: local(Poppins-Regular), url("../fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Poppins";
    src: local(Poppins-Bold), url("../fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

// -------------------Fonts close-----------------------//
body {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    -moz-osx-font-smoothing: grayscale;
    /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
    text-rendering: optimizeSpeed;
    background-color: $matterColorBright;
    -webkit-tap-highlight-color: transparent;
    /* for removing the highlight */
    outline: none !important;
    background-color: #f2f2f2;
    color: $blackColorThree;
}

a {
    @include avatusLinkStyles;
    outline: none !important;
}

h1 {
    @include avatusH1FontStyles;
}

h2 {
    @include avatusH2FontStyles;
}

h3 {
    @include avatusH3FontStyles;
}

h4 {
    @include avatusH4FontStyles;
}

h5 {
    @include avatusH5FontStyles;
}

h6 {
    @include avatusH6FontStyles;
}

input,
textarea,
select {
    @include avatusDefaultFontStyles;
    line-height: 24px;
}

p,
pre {
    @include avatusBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
    @include avatusDefaultFontStyles;
    color: $matterColor;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
}

ul {
    padding: 0;
    margin: 0;
}

legend,
label {
    @include avatusH5FontStyles;
    font-weight: $fontWeightSemiBold;
    display: block;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $breakpoint-small-minWidth) {
        font-weight: $fontWeightSemiBold;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

button {
    outline: none !important;
    cursor: pointer;
}

select {
    @include avatusSelectStyles;
}

textarea {
    @include avatusInputStyles;
    box-sizing: content-box;
    padding: 5px 0 8px 0;

    @media (min-width: $breakpoint-small-minWidth) {
        padding: 4px 0 8px 0;
    }
}

textarea::placeholder {
    padding: 0 0 4px 0;
}

.fontsLoaded body,
.fontsLoaded button {
    font-family: "poppins", Helvetica, Arial, sans-serif;
}

.MuiFormControl-root {
    max-width: 100%;
}

.MuiInputBase-root .MuiSelect-icon {
    top: 50% !important;
    transform: translateY(-50%);
    right: 12px;
    fill: #2a2a2a;
}

.MuiTablePagination-root .MuiSelect-icon {
    right: 0 !important;
}

.MuiTablePagination-select {
    font-family: "Poppins" !important;
}

.MuiInputBase-root .MuiSelect-icon path {
    fill: #2a2a2a !important;
}

//-----------Material --------------//
.dateOfBirthInput {
    .MuiInputLabel-outlined {
        transform: translateY(80%);
        padding: 0 !important;
        left: 10px;

        &.MuiInputLabel-shrink {
            left: 0;
        }
    }

    .MuiTextField-root {
        width: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
        border-image-slice: 1 !important;
        border-width: 1px;
        border-image: url("../svg/input-border-unselected.svg");
    }


    .Mui-error .MuiOutlinedInput-notchedOutline {
        border-image-slice: 1 !important;
        border-width: 1px;
        border-image: linear-gradient(to right,
                rgba(255, 0, 0, 0.65) 100%,
                rgba(203, 32, 32, 0.5) 50%,
                rgba(255, 0, 0, 0.52) -1%);
    }

    .MuiOutlinedInput-root {
        &.Mui-error {
            border-color: transparent !important;
        }
    }

    .MuiFormLabel-filled.Mui-focused,
    .MuiFormLabel-filled {
        color: #8a8a8a;
    }

    &.dateOfBirthInputLg {
        .MuiInputLabel-outlined {
            transform: translateY(18px);
            left: 20px;

            &.MuiInputLabel-shrink {
                left: 0;
                transform: translate(14px, -6px) scale(0.75);
                padding-right: 5px !important;
                padding-left: 5px !important;
            }
        }
    }
}

.dateOfBirthInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
}

.MuiButton-textPrimary {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    line-height: 24px;
    letter-spacing: 0.14px;
    outline: 0;
    display: inline-block;
    min-height: 36px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin: 0;
    padding: 0 20px !important;
    border: none;
    background-color: #db0716 !important;
    box-shadow: 0px 5px 5px #c1041133;
    text-transform: unset !important;
    color: #fff !important;
    text-align: center;
    text-decoration: none;
    transition: 0.4s;
    cursor: pointer !important;
    border-radius: 0 !important;
}

.MuiBadge-colorPrimary {
    color: #fff;
    background-color: #db0716 !important;
}

.MuiButton-textPrimary:hover,
.MuiButton-textPrimary__2uUXM:focus {
    box-shadow: 0 0 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}

.identity_wrapr {
    max-width: 370px;
    margin: 0 auto;

    .MuiOutlinedInput-multiline textarea {
        min-height: 155px;
    }
}

.request_dome_form {
    .MuiOutlinedInput-multiline textarea {
        min-height: 125px;
    }
}

//-----------Material close--------------//
.app_wrapper {
    position: relative;
    min-height: 100%;
    padding: 80px 0px 20px;
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
}

.custom_container {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 1200px) {
        max-width: 95%;
    }
}

.black_wrapper {
    background-color: $blackColorTwo;
    height: 100%;
}

.section_wrapper {
    padding: 40px 0 0 0;
    position: relative;

    @media (max-width: 650px) {
        padding: 20px 0 0 0;
    }

    &.top_wrap {
        padding: 60px 0 0 0;
    }
}

.form_field {
    margin-bottom: 25px;
}

.text_field {
    position: relative;
}

.mt-sm {
    margin-top: 15px;
}

.m0 {
    margin: 0 !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.ml-25 {
    margin-left: 25px;
}

.terms {
    .link {
        font-size: 12px;
    }
}

.commn_para {
    font-size: 14px;
    line-height: 1.43;
    color: $lightGreyColor;
    margin: 0 0 30px 0;

    @media (max-width: 650px) {
        font-size: 12px;
    }

    &.fs12 {
        font-size: 12px;
    }
}

.info {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    letter-spacing: 0.35px;
    color: $whiteColor;
    display: inline-block;
    margin-bottom: 20px;

    @media (max-width: 650px) {
        font-size: 12px;
    }
}

.char_count {
    font-size: 12px;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #aaa;
    text-align: right;
    display: block;
    margin-bottom: 30px;
}

.title {
    font: 32px/1.16 "Poppins";
    color: $whiteColor;
    margin-bottom: 32px;
    display: block;

    @media (max-width: 812px) {
        font-size: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        font-size: 26px;
        margin-bottom: 14px;
        max-width: 85%;
    }

    @media (max-width: 650px) {
        font-size: 18px;
        max-width: 100%;
        margin-bottom: 15px;
    }
}

.sub_title {
    font: 24px/1.88 "Poppins" !important;
    color: $whiteColor !important;
    letter-spacing: 0.6px;
    margin-bottom: 12px;
    display: block;
    position: relative;

    @media (max-width: 812px) {
        font-size: 22px;
    }

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 650px) {
        font-size: 18px;
    }
}

.xs_title {
    font: 16px/1.33 "Poppins";
    color: $whiteColor;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
    display: block;
    position: relative;
}

.back_btn {
    position: relative;
    padding: 0 0 0 30px;
    color: #ffffff;
    letter-spacing: 0.4px;
    font: normal normal normal 16px/1 "Poppins";
    margin-bottom: 40px;

    &::before {
        content: "";
        width: 32px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        background: url("../svg/ic-back.svg") center no-repeat;
        background-size: contain;

        @media (max-width: 650px) {
            left: -5px;
        }
    }
}

.request_dome_form {
    .back_btn {
        margin: 0;

        &::before {
            left: -90px;
        }
    }
}

.m_title {
    font: 20px/1.33 "Poppins";
    border-bottom: solid 0.5px #ffffff40;
    padding: 0px 0 15px;
    text-align: center;
    margin-bottom: 20px;
}

.m_content {
    max-height: 250px;
    overflow-y: auto;
}

.form_label {
    font: normal normal normal 14px/18px "Poppins";
    margin-bottom: 8px;
    color: $whiteColor;
    display: block;
}

.show_label {
    font: normal normal normal 14px/18px "Poppins";
    margin-bottom: 8px;
    color: $lightGreyColor;
    display: block;
    line-height: 1.5;
}

.form_wrapper {
    padding: 45px 0 30px;
    width: 487px;
    max-width: 487px;
    margin: 0 auto 0 0px;

    @media (max-width: 768px) {
        max-width: 95%;
    }

    @media (max-width: 650px) {
        padding: 10px 0;
        margin: auto;
        width: 325px;
    }

    .fill_red_btn {
        width: 100%;
    }
}

button {
    border: none;
    outline: none;
    padding: 0;
}

.fill_red_btn,
a.fill_red_btn {
    background-color: $avatusColor;
    letter-spacing: 0.3px;
    color: $whiteColor;
    padding: 4px 12px;
    transition: all ease-in 0.3s;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    width: 163px;
    max-width: 320px;
    border: none;

    &:hover {
        background-color: $avatusColor;
    }

    @media (max-width: 650px) {
        font-size: 12px;
    }

    &.disable_btn {
        background-color: $greyColor;
        cursor: not-allowed;
    }

    &.full_btn {
        max-width: 370px;
    }
}

.hide {
    display: none;
}

.outline_btn,
a.outline_btn {
    background-color: transparent;
    border: 1px solid #313134;
    letter-spacing: 0.3px;
    color: $whiteColor;
    padding: 8px 12px;
    transition: all ease-in 0.3s;
    display: inline-block;
    font-size: 14px;
    width: 100%;
    max-width: 320px;

    @media (max-width: 650px) {
        font-size: 12px;
        padding: 12px 18px;
    }

    &.disable_btn {
        background-color: #dfe5ef85;
        color: #99a4b6;
        pointer-events: none;
    }
}

input,
textarea {
    padding: 15px 18px;
    width: 100%;
    color: #8a8a8a;
    font-size: 14px;
    letter-spacing: 0.14px;
    background-color: transparent;
    letter-spacing: 0.5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: none;
}

input[type="search"]+span {
    display: none;
}

input[type="search"] {
    border: 1px solid #cacaca !important;
}

.detect_icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 !important;
    border: none !important;
    width: auto;
    width: 31px !important;
    padding: 0 !important;
    background: transparent !important;
    border: 0;
    min-height: unset !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.detect_icon:hover,
.detect_icon:focus {
    transform: translateY(-50%) !important;
}

.placeholder_otp {
    input {
        &::-webkit-input-placeholder {
            color: #333;
            font-size: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::-moz-placeholder {
            color: #333;
            font-size: 70px;
            opacity: 1;
        }

        &:-moz-placeholder {
            color: #333;
            font-size: 70px;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: #333;
            font-size: 70px;
        }
    }
}

//----------------Data not found---------------------//
.no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-flow: column;
    width: 100%;
    height: 100%;

    .no_img {
        margin: 0 auto 20px;
        max-width: 100%;

        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    span {
        text-align: center;
        color: #ffffffb3;
        font-size: 16px;
        line-height: 1.56;
        letter-spacing: 0.16px;
    }

    &.channel_success {
        .no_img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            padding: 10px;
            border: 2px solid #95cd86;

            // border-image-slice: 1 !important;
            // border-width: 1px;
            // border-image: url('../images/channel-success-border.svg') 30 fill / 30px / 30px space;
            img {
                border-radius: 50%;
            }
        }

        span {
            color: #aaa;
            font-size: 14px;
        }
    }
}

//----------------Data not found close---------------------//
//----------------- Scroll Bar ------------------//
html {
    scrollbar-color: #4a4a4a #ccc;
}

body {
    scrollbar-3dlight-color: #000000;
    scrollbar-arrow-color: #ffffff;
    scrollbar-darkshadow-color: #000000;
    scrollbar-face-color: #000000;
    scrollbar-highlight-color: #ffffff;
    scrollbar-shadow-color: #ffffff;
    scrollbar-track-color: #000000;
}

//-------------- Scroll Bar ------------------//
//-------------For Text Ellipsis---------------- //
.line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media (max-width: 650px) {
        -webkit-line-clamp: 2;
    }
}

//----------------- For Text Ellipsis close----------------//
li.active-bar img {
    filter: brightness(0) invert(1) !important;
}

li.active-bar {
    background: #2a2a2a;
}

li.active-bar a {
    color: #fff !important;
}

table {
    position: relative;
}

table .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
}

.MuiCircularProgress-root {
    color: #db0716 !important;
}

.MuiPaper-elevation1 {
    box-shadow: 0 0 !important;
}

.MuiPickersDatePickerRoot-toolbar {
    background: #2a2a2a !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockNumber-clockNumberSelected {
    background: #2a2a2a !important;
}

.MuiPickersClockPointer-thumb {
    border-color: #2a2a2a !important;
}

.MuiPickersBasePicker-pickerView {
    background: #fff;
    border-bottom: 1px solid #cccccc;
    min-width: 350px !important;
    max-width: 350px !important;
}

.MuiPickersBasePicker-pickerView .MuiTypography-colorPrimary {
    color: #db0716;
}

.MuiPickersDay-day.MuiPickersDay-daySelected,
.MuiPickersDay-day.MuiPickersDay-daySelected:hover {
    background: #db0716 !important;
    color: #fff !important;
}

.MuiPickersDay-day:hover {
    background: #2a2a2a !important;
    color: #fff !important;
}

.MuiPickersDay-day {
    color: #2a2a2a !important;
}

.MuiPickersToolbarText-toolbarTxt {
    color: #fff !important;
}

.rdw-editor-wrapper {
    margin-top: 20px;
}

.rdw-editor-wrapper .rdw-editor-main {
    box-sizing: border-box;
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #cacaca !important;
    color: #2a2a2a !important;
    min-height: 200px;
    resize: none;
    line-height: 1.75;
    font-size: 14px !important;
    margin-top: -6px;
    border-top: 0 !important;
    max-height: 200px;
}

.rdw-editor-main div,
.rdw-editor-main h3,
.rdw-editor-main h2,
.rdw-editor-main h1,
.rdw-editor-main h5,
.rdw-editor-main h6 {
    margin: 0;
}

.rdw-editor-toolbar {
    border-color: #cacaca;
    border-radius: 0;
}

.rdw-dropdown-selectedtext span {
    font-size: 14px;
    color: #2a2a2a !important;
}

.rdw-dropdown-wrapper:hover,
.rdw-dropdown-wrapper:focus {
    box-shadow: 0 0 !important;
}

.MuiPickersCalendarHeader-dayLabel {
    font-size: 14px !important;
}

.MuiDialogActions-root {
    line-height: 1 !important;
}

.MuiDialogActions-root button {
    font-family: "Poppins";
}

.MuiDialogActions-root span {
    line-height: 1;
}

input[type="password"],
input#password,
input#currentpassword,
input#newpassword,
input#confirmpassword {
    padding-right: 60px !important;
}

.headingWrapper {
    height: 200px;
}

.headingWrapper h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    font-size: 22px;
    color: #db0716;
}

.MuiSnackbar-anchorOriginTopRight {
    top: 11px;
}

.MuiSnackbarContent-root {
    background-color: #db0716 !important;
    box-shadow: 0px 0px 20px #0000000d !important;
    font-family: "Poppins", sans-serif !important;
    border-radius: 0 !important;
}

input[placeholder="Search"] {
    border: 0 !important;
    padding: 10px 10px 12px 34px;
}

input[placeholder="Search"]+span {
    position: absolute;
    left: 11px;
    top: 7px;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: white !important;
}

.MuiTableSortLabel-root:hover {
    color: #fff !important;
}

#menu-countryCode {
    .MuiPaper-root {
        width: 130px;
    }

    .MuiMenu-list {
        max-height: 180px !important;
    }

    input[placeholder="Search"] {
        border: 0 !important;
        padding: 4px 10px 4px 34px;
    }
}

.MuiBreadcrumbs-ol {
    li {
        font-size: 13px;

        a {
            color: #2a2a2a;

            &:hover {
                color: #2a2a2a !important;
                text-decoration: underline !important;
            }
        }

        &:last-child {
            p {
                font-size: 13px;
                font-weight: 600;
                color: #2a2a2a;
            }
        }
    }
}

.MuiPickersDay-day.MuiPickersDay-dayDisabled {
    color: #aaaaaa !important;
    cursor: not-allowed !important;
    box-shadow: 0 0 !important;
}

.MuiPickersDay-day {
    margin: 2px !important;
}

.MuiTabs-indicator {
    background-color: #2a2a2a !important;
}

.MuiMenu-list {
    .MuiListItem-root {
        .MuiListItemText-primary {
            font-size: 14px;
        }

        .MuiIconButton-root {
            padding: 0;
        }

        .MuiListItemIcon-root {
            min-width: 36px;
        }

        &:hover {
            background-color: #f6f6f6 !important;
            color: #2a2a2a !important;
        }
    }
}

.badge {
    .MuiChip-root {
        .MuiChip-label {
            line-height: 20px;
        }
    }
}

.MuiMenu-paper {
    max-height: 100%;
}

.notification_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }

    button {
        font-size: 12px;
        font-family: $fontFamily;
    }
}

.notification_menu_wrap {
    max-height: 300px;
    overflow-y: auto;
}

.notification_menu {
    position: absolute;
    min-width: 350px;
    right: 0;
    background: white;
    z-index: 1;
    top: 100%;
    margin-top: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 20px #0000000d;

    .notification_topbar {
        position: sticky;
        top: 0;
        z-index: 10;
        background: white;
        width: 100%;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #cccccc;
        font-size: 13px;
        padding: 6px 20px;
    }

    .notification_menu_item {
        padding: 10px 20px;

        &:not(:nth-last-of-type(1)) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .d_flex {
            align-items: flex-start;
            display: flex;
            position: relative;
        }

        .user_img {
            width: 40px;
            height: 40px;
            margin: 0 15px 0 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .notification_item_content {
            width: calc(100% - 55px);
            overflow: hidden;

            p {
                font-size: 13px;
                display: block;
                margin: 0;
                line-height: normal;
                white-space: normal;
                @include text-truncate(2);
            }

            .time {
                margin-top: 12px;
                display: inline-block;
                font-size: 11px;
            }
        }

        .clear_btn {
            svg {
                width: auto;
                font-size: 18px;
                opacity: .7;
            }

            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        &.messageNotRead {
            .d_flex {
                &::before {
                    position: absolute;
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: red;
                    border-radius: 50%;
                    top: 20px;
                    left: -10px;
                }
            }
        }
    }
}



.notification_dot {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background-color: red;
        border-radius: 50%;
        top: 0;
        right: 2px;
    }
}
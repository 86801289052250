@import "./customMediaQueries.scss";

/* ================ Colors ================ */

$avatusColor: #db0716;
$avatusColorLight: #db0716;
$avatusColorDark: #db0716;
$primaryColorDark: #db0716;
$brownColorDark: #db0716;
$whiteColor: #ffffff;
$blackColor: #000000;
$blackColorTwo: #2a2a2a;
$blackColorThree: #333333;
$blackColorFour: #120e24;
$greyColorTwo: #404040;
$darkGreyColor: #2a2a2a;
$greyColor: #f2f2f2;
$lightGreyColor: #aaaaaa;
$zIndexModal: 1;
$zIndexModal: 1;
$grayColor: #808080;
$shadowColor: #0000000d;
$blueColor: #5282f0;
$grayColorLight: #d2d2d2;
$labelColor: #8a8a8a;
$btnShadowColor: #c1041133;
$silverColor: #cccccc;

/* Used with inline CSS SVGs */

$avatusColorEncoded: "%23c0392b";
$successColor: #00aff0;
$successColorDark: #00aff0;
$successColorLight: #f0fff6;
$failColor: #fd1c24;
$failColorLight: #fff0f0;
$attentionColor: #ffaa00;
$attentionColorLight: #fff7f0;
$bannedColorLight: $avatusColorLight;
$bannedColorDark: $avatusColor;
$matterColorDark: #000000;
$matterColor: #4a4a4a;
$matterColorAnti: #b2b2b2;
$matterColorNegative: #404040;
$matterColorBright: #fcfcfc;
$matterColorLight: #ffffff;
$matterColorPlaceholder: #979797;
$matterColorConcrete: #f2f2f2;
$successColorGreen: #2ecc71;
$matterColorLightTransparent: rgba(255, 255, 255, 0.65);

/* ================ Font ================ */

$fontFamily: "poppins",
Helvetica,
Arial,
sans-serif;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightHighlightEmail: $fontWeightBold;

/* ================ Spacing unites ================ */

/* Multiples of mobile and desktop spacing units should be used with margins and paddings. */

$spacingUnit: 6px;
$spacingUnitDesktop: 8px;

/* Shadows */

$boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
$boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
$boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
$boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
$boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
$boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
$boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
$boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
$boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
$boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

/* ================ Z-index base levels ================ */

/* topbar is positioned slightly above content */

$zIndexTopbar: 10;

/* small popups on UI should use z-indexes above 50 */

$zIndexPopup: 50;

/* modals and UI overlays should use z-indexes above 100 */

$zIndexModal: 100;

/* generic error message should overlay even modals */

$zIndexGenericError: 200;

/* ================ Border radius ================ */

$borderRadius: 20px;
$borderRadiusMobileSearch: 3px;

/* ================ Transition styles ================ */

$transitionStyle: ease-in 0.2s;
$transitionStyleButton: ease-in-out 0.1s;

/* ================ Topbar related ================ */

$topbarHeight: 60px;
$topbarHeightDesktop: 72px;
$TopbarMobileMenu_topMargin: 96px;
$Topbar_logoHeight: 25px;
$CheckoutPage_logoHeight: 25px;
$CheckoutPage_logoHeightDesktop: 27px;
$TopbarSearchForm_inputHeight: 53px;
$TopbarSearchForm_topbarMargin: 94px;
$TopbarSearchForm_bottomBorder: 3px;

/* ================ Modal default padding ================ */

$modalPadding: 24px;
$modalPaddingMedium: 55px 60px 55px 60px;

/* ================ LocationAutocompleteInput bottom attribution padding ================ */

/* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */

$locationAutocompleteBottomPadding: 8px;
$LocationAutocompleteInput_inputHeight: 50px;
$LocationAutocompleteInput_sidePaddingDesktop: 36px;

/* ================ Filters ================ */

$avatusButtonSmallDesktopPadding: 9px 16px 9px 16px;

/* ================ DateInput, DateRangeInput, DateRangeController ================ */

$ReactDates_selectionHeight: 36px;
$ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);
$DateInput_selectionHeight: $ReactDates_selectionHeight;
$DateInput_hoveredOverlayColor: $ReactDates_hoveredOverlayColor;
$DateRangeInput_selectionHeight: $ReactDates_selectionHeight;
$DateRangeInput_hoveredOverlayColor: $ReactDates_hoveredOverlayColor;
$DateRangeController_selectionHeight: $ReactDates_selectionHeight;

/* ================ SectionHero ================ */

$SectionHero_desktopTitleMaxWidth: 625px;

/* ================ TabNav ================ */

$TabNav_linkWidth: 240px;

/* ================ LandingPage ================ */

$LandingPage_sectionMarginTop: 40px;
$LandingPage_sectionMarginTopMedium: 60px;
$LandingPage_sectionMarginTopLarge: 94px;

/* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */

$ManageAvailabilityCalendar_gridColor: #e0e0e0;
$ManageAvailabilityCalendar_availableColor: #ffffff;
$ManageAvailabilityCalendar_availableColorHover: #fafafa;
$ManageAvailabilityCalendar_blockedColor: #ebebeb;
$ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
$ManageAvailabilityCalendar_reservedColor: #e6fff0;
$ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
$ManageAvailabilityCalendar_failedColor: #fff2f2;

/* ================ ProfileSettingsForm ================ */

$ProfileSettingsForm_avatarSize: 96px;
$ProfileSettingsForm_avatarSizeDesktop: 240px;

/* ================ CSS Custom Property Sets ================ */

@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin falign($value) {
    @include flexbox;
    -webkit-box-align: $value;
    align-items: $value;
}

@mixin fpos($a-item, $j-align) {
    @include flexbox;
    align-items: $a-item;
    justify-content: $j-align;
}

@mixin avatusDefaultFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-size: 16px;
        line-height: 32px;
    }
}

@mixin avatusSmallFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 24px;
}

/* ================ Body font ================ */

@mixin avatusBodyFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-size: 16px;
        line-height: 32px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

/* ================ Hero title ================ */

@mixin avatusHeroTitleFontStyles {
    font-weight: $fontWeightBold;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.7px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 25px;
    margin-bottom: 14px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-size: 64px;
        line-height: 64px;
        letter-spacing: -1.5px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 25px;
        margin-bottom: 23px;
    }
}

/* ================ Topbar ================ */

/* Compose text label items on top of this */

/* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */

@mixin TopbarDesktop_label {
    display: inline-block;
    margin: 28px 0;
    text-decoration: inherit;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin: 28px 0;
    }
}

@mixin TopbarDesktop_linkHover {
    border-bottom: 0px solid;
    transition: $transitionStyleButton;

    &:hover,
    &:focus {
        border-bottom: 4px solid $avatusColor;
        border-radius: 0;
        text-decoration: none;
    }
}

/* ================ Modal title ================ */

@mixin avatusModalTitleStyles {
    font-weight: $fontWeightSemiBold;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-weight: $fontWeightSemiBold;
        line-height: 40px;
        margin: 0;
    }
}

/* ================ H1, H2, H3, H4, H5 & H6 ================ */

@mixin avatusH1FontStyles {
    font-weight: $fontWeightSemiBold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    position: relative;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 18px;
    margin-bottom: 18px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-size: 40px;
        font-weight: $fontWeightSemiBold;
        line-height: 48px;
        letter-spacing: -1px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 24px;
        margin-bottom: 24px;
    }

    @media (max-width: $breakpoint-small-maxWidth) {
        font-size: 20px;
    }
}

@mixin avatusH2FontStyles {
    font-weight: $fontWeightSemiBold;
    font-size: 21px;
    line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 21px;
    margin-bottom: 17px;

    @media (min-width: $breakpoint-medium-minWidth) {
        line-height: 32px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 0;
        margin-bottom: 19px;
    }
}

@mixin avatusH3FontStyles {
    font-weight: $fontWeightSemiBold;
    font-size: 18px;
    line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 16px;
    margin-bottom: 14px;

    @media (min-width: $breakpoint-medium-minWidth) {
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 16px;
        margin-bottom: 16px;
    }

    @media (max-width: $breakpoint-medium-maxWidth) {
        font-size: 16px;
    }
}

@mixin avatusH4FontStyles {
    font-weight: $fontWeightMedium;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-weight: $fontWeightMedium;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 17px;
        margin-bottom: 15px;
    }
}

@mixin avatusH5FontStyles {
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (min-width: $breakpoint-medium-minWidth) {
        line-height: 16px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 10px;
        margin-bottom: 14px;
    }
}

@mixin avatusH6FontStyles {
    font-weight: $fontWeightBold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (min-width: $breakpoint-medium-minWidth) {
        line-height: 16px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 10px;
        margin-bottom: 6px;
    }
}

/* ================ Other fonts ================ */

@mixin avatusTinyFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightRegular;
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (min-width: $breakpoint-medium-minWidth) {
        line-height: 16px;
        /* margin-top + n * line-height + margin-bottom => x * 8px */
        margin-top: 10.5px;
        margin-bottom: 13.5px;
    }
}

@mixin avatusMessageFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightRegular;
    font-size: 16px;
    line-height: 24px;
}

@mixin avatusMessageDateFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightRegular;
    font-size: 12px;
    line-height: 18px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-weight: $fontWeightMedium;
        font-size: 12px;
        line-height: 24px;
    }
}

@mixin avatusTxTransitionFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 16px;
    line-height: 18px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-size: 16px;
        line-height: 24px;
    }
}

@mixin avatusSearchFilterLabelFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightSemiBold;
    font-size: 13px;
    line-height: 18px;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-weight: $fontWeightMedium;
        font-size: 13px;
        line-height: 20px;
    }
}

@mixin avatusSearchFilterSublabelFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 18px;
    line-height: 18px;
}

@mixin avatusListingAttributeFontStyles {
    font-family: $fontFamily;
    font-weight: $fontWeightMedium;
    font-size: 16px;
    line-height: 24px;
}

/* ================ Tabbed navigation font styles ================ */

@mixin avatusTabNavFontStyles {
    font-weight: $fontWeightMedium;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;

    @media (min-width: $breakpoint-large-minWidth) {
        font-weight: $fontWeightSemiBold;
        font-size: 20px;
        line-height: 24px;
    }
}

@mixin avatusTabNavHorizontalFontStyles {
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
}

/* ================ Clearfix solution ================ */

--clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

/* ================ Links ================ */

@mixin avatusLinkStyles {
    /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;
    /* Borders */
    border: none;
    /* Colors */
    color: $avatusColor;
    /* Text size should be inherited */
    text-decoration: none;
    transition: 0.2s ease;
    outline: none;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }

    &:hover,
    &:focus {
        outline: 0;
        color: $whiteColor;
    }
}

/* ================ Buttons ================ */

@mixin avatusButtonFontStyles {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: $fontWeightSemiBold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.14px;
    outline: 0;

    @media (min-width: $breakpoint-medium-minWidth) {
        /* TODO: Make sure button text aligns with the baseline */
    }

    &:hover,
    &:focus {
        outline: 0;
    }
}

@mixin avatusButtonStyles {
    @include avatusButtonFontStyles;
    display: inline-block;
    min-height: 36px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin: 0;
    padding: 0 20px !important;
    border: none;
    background-color: #db0716;
    box-shadow: 0px 5px 5px #c1041133;
    text-transform: unset !important;
    color: #fff;
    text-align: center;
    text-decoration: none;
    transition: 0.4s;
    cursor: pointer !important;

    &:hover,
    &:focus {
        outline: none;
        background-color: #db0716;
        text-decoration: none;
        box-shadow: 0 0 !important;
    }

    &:disabled {
        background-color: #aaaaaa;
        color: #fff;
        cursor: not-allowed !important;
        box-shadow: 0 0 !important;
    }

    @media (min-width: $breakpoint-medium-minWidth) {
        min-height: 65px;
    }
}

@mixin avatusButtonStylesPrimary {
    @include avatusButtonStyles;
    background-color: $successColor;
    color: $matterColorLight;

    &:hover,
    &:focus {
        background-color: $successColorDark;
        outline: none;
    }

    &:disabled {
        background-color: $matterColorNegative;
        color: $matterColorLight;
    }
}

@mixin avatusButtonStylesSecondary {
    @include avatusButtonStyles;
    background-color: $matterColorLight;
    color: $matterColor;
    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
    padding: 18px 0 0 0;
    border-width: 1px;
    border-style: solid;
    border-color: $matterColorNegative;

    &:hover,
    &:focus {
        background-color: $matterColorLight;
        border-color: $matterColorAnti;
        outline: none;
    }

    &:disabled {
        background-color: $matterColorNegative;
        color: $matterColorLight;
        border-color: $matterColorNegative;
    }
}

@mixin text-truncate($line, $height: "") {
    display: block !important;
    display: -webkit-box !important;
    @include line-clamp($line);
    @include box-orient();
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: $height;
    min-height: $height;
}

@mixin line-clamp($line) {
    -webkit-line-clamp: $line;
    -moz-line-clamp: $line;
    -ms-line-clamp: $line;
    -o-line-clamp: $line;
    line-clamp: $line;
}

@mixin box-orient($value: vertical) {
    -webkit-box-orient: $value;
    -moz-box-orient: $value;
    -ms-box-orient: $value;
    -o-box-orient: $value;
    box-orient: $value;
}

/* ================ Inputs ================ */

@mixin avatusInputStyles {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    /* Borders */
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $avatusColor;
    border-radius: 0;
    font-family: Poppins !important;

    &::placeholder {
        color: #8a8a8a;
        font-size: 14px;
    }

    /* Effects */
    transition: border-bottom-color $transitionStyle;

    &:hover,
    &:focus {
        border-bottom-color: $matterColor;
        outline: none;
    }

    @media (min-width: $breakpoint-medium-minWidth) {
        padding: 4px 0 6px 0;
    }
}

@mixin avatusSelectStyles {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;
    /* Unset user agent styles */
    appearance: none;
    /* Borders */
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: $avatusColor;
    border-radius: 0;
    background-size: 16px 16px;
    background-position: center right;
    /* Effects */
    cursor: pointer;
    transition: border-bottom-color $transitionStyle;

    &:hover,
    &:focus {
        border-bottom-color: $matterColor;
        outline: none;
    }

    @media (min-width: $breakpoint-medium-minWidth) {
        padding: 4px 0 6px 0;
    }
}

/* ================ Modals ================ */

@mixin avatusModalRootStyles {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;
    /* AuthenticationPage's root uses flexbox */
    display: flex;

    @media (min-width: $breakpoint-medium-minWidth) {
        justify-content: center;
        align-items: flex-start;
    }
}

@mixin avatusModalFormRootStyles {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
}

@mixin avatusModalBaseStyles {
    flex-grow: 1;
    /* Create context so the close button can be positioned within it */
    position: relative;
    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: $modalPadding;
    border-radius: $borderRadius;
    border-bottom: none;
}

@mixin avatusModalInMobileBaseStyles {
    flex-grow: 1;
    /* Create context so the close button can be positioned within it */
    position: relative;
    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;
    background-color: $matterColorLight;
    border-radius: $borderRadius;

    @media screen and (min-width: 992px) {
        flex-basis: 480px;
        flex-grow: 0;
        height: 100%;
        padding: 0;
        background-color: transparent;
        border-radius: 0;
    }
}

/* Modal title */

@mixin avatusModalTitleStyles {
    font-weight: $fontWeightSemiBold;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: $fontWeightBold;

    @media (min-width: $breakpoint-medium-minWidth) {
        font-weight: $fontWeightSemiBold;
        line-height: 40px;
        letter-spacing: -0.9px;
        margin: 0;
    }
}

/* Modal message */

@mixin avatusModalParagraphStyles {
    margin: 18px 0 0 0;
    font-weight: $fontWeightLight;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin: 24px 0 0 0;
    }
}

/* Bottom wrapper for the modal */

@mixin avatusModalBottomWrapper {
    text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin-top: 96px;
    }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */

@mixin avatusModalBottomWrapperText {
    margin-top: -24px;
    padding-bottom: 8px;

    @media (min-width: $breakpoint-medium-minWidth) {
        padding-bottom: 0;
    }
}

@mixin avatusModalHelperText {
    @include avatusTinyFontStyles;
    color: $matterColorAnti;
    margin: 0;
    line-height: 24px;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin: 0;
    }
}

@mixin avatusModalHelperLink {
    @include avatusTinyFontStyles;
    color: $matterColor;
    margin: 0;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin: 0;
    }
}

/* Modal Close icon */

@mixin avatusModalCloseStyles {
    /* Position inside modal */
    position: fixed;
    top: 20px;
    right: 25px;
    cursor: pointer;
    z-index: 9;
    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: $zIndexModal + 1;
    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: center;
    width: auto;
    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    @media (min-width: $breakpoint-medium-minWidth) {
        position: absolute;
        padding: 0;
    }

    /* Colors */
    background-color: transparent;
    color: $whiteColor;
    cursor: pointer;

    &:enabled:hover {
        background-color: transparent;
        color: $whiteColor;
        box-shadow: none;
        text-decoration: none;
    }

    &:enabled:active {
        background-color: transparent;
        color: $whiteColor;
    }

    &:disabled {
        background-color: transparent;
    }
}

@mixin avatusModalIconStyles {
    height: 48px;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-medium-minWidth) {
        height: 64px;
        margin-bottom: 23px;
    }
}

@mixin avatusModalCloseText {
    /* Font */
    @include avatusH6FontStyles;
    margin: -2.5px 0 0 0;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin-top: -1px;
    }
}

@mixin avatusModalCloseIcon {
    display: inline-block;
    max-width: 14px;
    padding: 2px 0 4px;
    box-sizing: content-box;
}

@mixin avatusModalErrorStyles {
    @include avatusH4FontStyles;
    margin-top: 24px;
    color: $failColor;
}

@mixin avatusModalPasswordMargins {
    /* Leave space between the input and the button below when the
    viewport height is small */
    margin-top: 24px;

    @media (min-width: $breakpoint-medium-minWidth) {
        margin-top: 32px;
    }
}

//------------------------------------------------------//
.MuiTypography-root,
.MuiInput-root,
.MuiMenuItem-root {
    font-family: "Poppins" !important;
}

.MuiFormHelperText-root,
.MuiAlert-root {
    font-family: "Poppins" !important;
    font-weight: 500;
}

input[type="number"] {
    font-family: "Poppins";
}

#card-more-menu {
    .MuiPaper-root {
        margin-left: 12px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.86);
    }

    .MuiMenuItem-root {
        padding: 5px 0 !important;

        label {
            font-weight: 300;
        }

        img {
            margin-right: 12px;
        }
    }
}

.MuiMenu-list {
    max-height: 230px !important;
    overflow-x: hidden !important;
    padding: 0 !important;
}

.MuiPaper-rounded {
    border-radius: 0 !important;
}

.MuiMenuItem-root {
    font: normal normal normal 12px/16px Poppins !important;
    letter-spacing: 0.12px !important;
    color: #2a2a2a !important;
    margin: 0 20px 10px !important;
    overflow: initial !important;
    min-height: 38px !important;
    width: 100%;
    margin: 0 !important;
    transition: none !important;

    span.MuiTouchRipple-root,
    .MuiTouchRipple-rippleVisible {
        animation: none;
        opacity: 0;
        transition: none;
    }

    &:hover {
        background-color: #2a2a2a !important;
        color: #fff !important;

        l &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: -20px;
            border-right: 20px solid #0c0c0c;
            border-top: 18px solid transparent;
            border-bottom: 20px solid transparent;
            display: none;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0px;
            right: -20px;
            border-left: 20px solid #0c0c0c;
            border-top: 18px solid transparent;
            border-bottom: 20px solid transparent;
            display: none;
        }
    }

    &.Mui-disabled {
        display: none;
    }

    &.Mui-selected {
        background-color: #000000 !important;
        position: relative;
        color: #fff !important;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: -20px;
            border-right: 20px solid #db0716;
            border-top: 18px solid transparent;
            border-bottom: 20px solid transparent;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0px;
            right: -20px;
            border-left: 20px solid #db0716;
            border-top: 18px solid transparent;
            border-bottom: 20px solid transparent;
        }
    }
}

.MuiPickersBasePicker-container {
    background-color: $darkGreyColor !important;

    .MuiSelect-select.MuiSelect-select {
        padding: 10px 24px 10px 2px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
        letter-spacing: 0.14px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MuiInputBase-root .MuiSelect-icon {
        right: 10px;
    }
}

.MuiInput-underline.Mui-focused:after,
.MuiInput-underline.Mui-focused:before {
    display: none;
}

.MuiInput-underline.Mui-error:after {
    border-bottom: 0 !important;
}

.MuiPickersDay-day {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.14px;
    color: $whiteColor !important;

    &.MuiPickersDay-daySelected {
        background: url("../svg/date-marker-red.svg") center no-repeat !important;

        &:hover {
            background: url("../svg/date-marker-red.svg") center no-repeat !important;
        }
    }

    &:hover {
        background: url("../svg/date-marker-black.svg") center no-repeat !important;
    }

    &:hover,
    &:focus {
        span.MuiTouchRipple-root {
            animation: none;
            opacity: 0;
            transition: none;
        }
    }
}

.MuiPickersDay-dayDisabled {
    color: #ffffff33 !important;
}

.MuiPickersCalendarHeader-dayLabel,
.MuiTypography-caption {
    font-size: 10px !important;
    letter-spacing: 0.1px !important;
    color: #8a8a8a !important;
    text-transform: uppercase;
}

.MuiAutocomplete-popupIndicator {
    display: none !important;
}

//-------------------------------------------------------//

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

//--------------------Effects-----------------------//

/*---Link hover effect-------*/

.link {
    position: relative;
    font: normal normal normal 14px/14px "Poppins";
    color: $whiteColor;
    letter-spacing: 0.3px;
    cursor: pointer;

    @media (max-width: 320px) {
        font-size: 11px;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background: $whiteColor;
        -webkit-transition: all ease 0.4s;
        -o-transition: all ease 0.4s;
        transition: all ease 0.4s;
    }

    &:hover {
        &:after {
            width: 100%;
        }
    }
}

.buttonLink {
    border: none;
}

/*-------Link hover effect close--------*/

/*------Bell effect------*/

.bell {
    img {
        &:hover {
            animation: ring 0.8s ease;
        }
    }
}

@keyframes ring {
    0% {
        transform: rotate(35deg);
    }

    12.5% {
        transform: rotate(-30deg);
    }

    25% {
        transform: rotate(25deg);
    }

    37.5% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(15deg);
    }

    62.5% {
        transform: rotate(-10deg);
    }

    75% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

/*--------Bell effect close-------*/

/*--------------------circle effect---------------------*/

.circle {
    position: relative;
    border-radius: 100%;
    box-shadow: none;

    &:hover {
        color: $avatusColor;
    }

    &::before,
    &::after {
        content: "";
        top: 0;
        left: 0;
        box-sizing: inherit;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }

    &::before {
        border: 2px solid transparent; // We're animating border-color again
    }

    &:hover::before {
        border-top-color: $avatusColor; // Show borders
        border-right-color: $avatusColor;
        border-bottom-color: $avatusColor;
        transition: border-top-color 0.15s linear,
            // Stagger border appearances
            border-right-color 0.15s linear 0.1s,
            border-bottom-color 0.15s linear 0.2s;
    }

    &::after {
        border: 0 solid transparent; // Makes border thinner at the edges? I forgot what I was doing
    }

    &:hover::after {
        border-top: 2px solid $avatusColor; // Shows border
        border-left-width: 2px; // Solid edges, invisible borders
        border-right-width: 2px; // Solid edges, invisible borders
        transform: rotate(270deg); // Rotate around circle
        transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; // Solid edge post-rotation
    }
}

/*--------------------circle effect close---------------------*/

/*-------btn effect----------*/

.btn-effect {
    position: relative;
}

.btn-effect:before {
    position: absolute;
    width: 90%;
    height: 90%;
    background: transparent;
    border: 1px solid transparent;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-effect:hover:before {
    width: 105%;
    // border-image: linear-gradient( to right, rgba(255, 255, 255, 0.2) 100%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) -1% );
    border-image: url("../svg/Button-border.svg");
    border-width: 1px;
    border-image-slice: 1;
    height: 125%;
}

/*------btn effect close-------*/

//--------------------Effects close-----------------------//
//-----------------------Grid-----------------------------//
.flex_row {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
}

.flex_col_sm_2 {
    width: 16.66666667%;
    padding: 0 10px;
    position: relative;
}

.flex_col_sm_3 {
    width: 25%;
    padding: 0 10px;
}

.flex_col_sm_4 {
    width: 33.3%;
    padding: 0 10px !important;

    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 50%;
    }

    @media (max-width: 650px) {
        width: 100%;
    }
}

.flex_col_sm_5 {
    width: 41.666667%;
    padding: 0 10px;

    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        width: 75%;
    }

    @media (max-width: 650px) {
        width: 100%;
    }
}

.flex_col_sm_6 {
    width: 50%;
    padding: 0 10px !important;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.flex_col_xs_6 {
    width: 50%;
    padding: 0 10px;
}

.flex_col_sm_7 {
    width: 58.333333%;
    padding: 0 10px;

    @media (max-width: 650px) {
        width: 100%;
    }
}

.flex_col_sm_8 {
    width: 66.666667%;
    padding: 0 10px;

    @media (max-width: 768px) {
        width: 100%;
    }
}

.flex_col_sm_9 {
    width: 75%;
    padding: 0 10px;
}

.flex_col_sm_10 {
    width: 83.33333333%;
    padding: 0 10px;
}

.flex_col_sm_12 {
    width: 100%;
    padding: 0 10px !important;
}

.end_flex {
    align-items: flex-end;
}

.f_col {
    @media (max-width: 650px) {
        flex-flow: column;
    }
}

.f_reverse {
    @media (max-width: 650px) {
        flex-flow: column-reverse;
    }
}

.center_flex {
    align-items: center;

    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        align-items: flex-start;
    }
}

.vertical_mid {
    vertical-align: middle;
}

.f_row {
    display: flex;
}

.f_column {
    display: flex;
    flex-flow: column;
}

.row_wrap {
    display: flex;
}

.f_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.f_spacebw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-center {
    text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

table {
    .MuiCheckbox-root {
        padding: 0 !important;
    }
}

//-----------------------Grid close-----------------------------//

.MuiAutocomplete-popper {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .MuiAutocomplete-listbox {
        font-family: "Poppins";

        .MuiAutocomplete-option {
            font-size: 14px;
        }
    }

    .MuiAutocomplete-noOptions {
        font-family: "Poppins";
        font-size: 15px;
    }
}

.MuiPickersBasePicker-pickerView {
    min-height: 340px !important;
    justify-content: flex-start !important;
}

.MuiChip-root {
    height: auto !important;
    padding: 4px !important;
    font-size: 11px !important;
    margin-bottom: 3px;
    margin-right: 3px;

    .MuiChip-label {
        font-family: "Poppins";
        padding: 0 5px;
    }
}

label#mutiple-select-label {
    &+.MuiInput-formControl {
        margin-top: 0 !important;
        text-align: left;
    }

    &.MuiFormLabel-filled {
        display: none;
    }

    &.Mui-focused {
        color: #b3b3b3;
    }
}

.MuiFormControlLabel-label {
    font-size: 14px !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #8a8a8a !important;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #2a2a2a !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.MuiOutlinedInput-root {
    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: $blackColor !important;
        }
    }
}

.MuiInputBase-input,
.MuiInputLabel-outlined {
    font-family: $fontFamily !important;
}